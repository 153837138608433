
@mixin ellipse1 {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@mixin ellipse3 {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}
.annual-meeting-page {
    .com-wrap {
        img {
            height: 93px;
            width: auto;
            margin: 45px auto;
            display: block;
        }
    }
    // .wordcloud {
    //     width: 100%;
    //     height: 375px;
    //     background: #f5f3f3;
    // }
    .wordcloud {
        width: 100%;
        padding: 60px 0;
        box-sizing: border-box;
        background: rgba(245, 243, 243, 0.5);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 1200px;
            display: block;
            height: 200px;
        }
    }
    .news {
        width: 100%;
        height: 448px;
        background: url(../assets/img/annual-metting/bg-news4.png);
        background-repeat: repeat-x;
        background-size: auto 100%;
        position: relative;
        z-index: 0;
        #newsBg {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            z-index: 0;
        }
        .news-content {
            
            z-index: 2;
            height: 100%;
            background: rgba(13, 16, 32, 0.5);
            // padding: 19px 30px 19px 20px;
            padding: 19px 25px;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            ul {
                height: 364px;
                li {
                    width: 100%;
                    height: 51px;
                    line-height: 51px;
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px dashed rgba(255, 255, 255, 0.2);

                    a {
                        width: 850px;
                        height: 51px;
                        line-height: 51px;
                        display: block;
                        position: relative;
                        padding-left: 17px;
                        color: #fff;
                        font-size: 16px;

                        cursor: pointer;
                        @include ellipse1();
                    }
                    a::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 6px;
                        height: 6px;
                        background: #ffffff;
                        border-radius: 50%;
                    }
                    span {
                        display: block;
                        font-size: 14px;
                        color: #fff;
                    }
                }
                li:nth-child(2n + 2) {
                    margin-right: 0;
                }
            }
        }
    }
    .expert {
        width: 100%;
        height: 530px;
        // background: red;
    }
    .review {
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                width: 585px;
                height: 307px;
                margin-right: 15px;
                margin-bottom: 15px;
                position: relative;
                z-index: 0;
                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                }
                h6 {
                    height: 35px;
                    text-align: center;
                    font-size: 33px;
                    font-weight: 800;
                    color: #1c323b;
                    line-height: 35px;
                    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.37);
                    background: linear-gradient(0deg, #b28355 0%, #8a5549 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin-bottom: 50px;
                    padding-top: 70px;
                    // @include ellipse1();
                }
                p,
                span {
                    display: block;
                    text-align: center;
                    text-align: center;
                    height: 32px;
                    font-size: 30px;
                    font-weight: 800;
                    color: #1c323b;
                    line-height: 32px;
                    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.37);

                    background: linear-gradient(0deg, #b28355 0%, #8a5549 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin-bottom: 24px;
                }
            }
            li:nth-last-child(2), li:last-child {
                margin-bottom: 0;
            }
        }
    }
    .chronicle-of-events {
        display: flex;
        margin-bottom: 45px;
        .side {
            width: 118px;
            min-height: 450px;
            background: url(../assets/img/annual-metting/bg-time.png);
            background-repeat: no-repeat repeat;
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
                font-size: 20px;
                font-family: Source Han Serif CN;
                font-weight: 800;
                color: #f3f1f1;
                text-align: center;
            }
            span {
                display: block;
                color: #fff;
                text-align: center;
                font-size: 20px;
            }
        }
        .chronicle {
            width: 1082px;
            min-height: 450px;
            background: #f5f5f5;
            ul {
                padding-bottom: 25px;
                li {
                    padding: 0 72px;
                    box-sizing: border-box;
                    margin-top: 25px;
                    display: flex;
                    align-items: center;
                    
                    .time {
                        width: 55px;
                        margin-right: 30px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        position: relative;
                        // margin-bottom: 18px;

                        h6 {
                            width: 22px;
                            height: 22px;
                            background: rgba(182, 173, 174, 0.5);
                            border-radius: 50%;
                            text-align: center;
                            line-height: 22px;
                            font-size: 14px;
                            font-family: FZKai-Z03S;
                            font-weight: bold;
                            color: #2b221b;
                            // margin-bottom: 4px;
                        }
                        p {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);

                            text-align: center;
                            font-size: 14px;
                            font-family: FZKai-Z03S;
                            font-weight: 400;
                            color: #2b221b;
                            line-height: 14px;
                        }
                    }
                    h5 {
                        // margin-bottom: 18px;
                        a {
                            width: 848px;
                            display: block;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #2b221b;
                            // @include ellipse1();
                        }
                    }
                }
            }
        }
    }
}
